import request from '@/utils/request'

export function consoleBlocks(params) {
  if (params.endDate && params.endDate.indexOf('23:59:59') == -1)
    params.endDate += ' 23:59:59';

  return request({
    url: '/StockStore/Console',
    method: 'get',
    params: params
  });
}

export function consoleTable(params) {
  if (params.endDate && params.endDate.indexOf('23:59:59') == -1)
    params.endDate += ' 23:59:59';
  return request({
    url: '/StockStore/ConsoleTable',
    method: 'get',
    params: params
  });
}

export function consoleExport(params) {
  if (params.endDate && params.endDate.indexOf('23:59:59') == -1)
    params.endDate += ' 23:59:59';
  return request({
    url: '/StockStore/ConsoleExport',
    method: 'get',
    params: params
  });
}

//日期处理  
var now = new Date(); //当前日期     
var nowDayOfWeek = now.getDay(); //今天本周的第几天     
var nowDay = now.getDate(); //当前日     
var nowMonth = now.getMonth(); //当前月     
var nowYear = now.getFullYear(); //当前年  

//格式化日期：yyyy-MM-dd     
function formatDate(date) {
  var myyear = date.getFullYear();
  var mymonth = date.getMonth() + 1;
  var myweekday = date.getDate();
  if (mymonth < 10) {
    mymonth = "0" + mymonth;
  }
  if (myweekday < 10) {
    myweekday = "0" + myweekday;
  }
  return (myyear + "-" + mymonth + "-" + myweekday);
}

//获得当前格式化日期：yyyy-MM-dd      
function getNowFormatDate() {
  return formatDate(now);
}

//获得某月的天数     
function getMonthDays(myMonth) {
  var monthStartDate = new Date(nowYear, myMonth, 1);
  var monthEndDate = new Date(nowYear, myMonth + 1, 1);
  var days = (monthEndDate - monthStartDate) / (1000 * 60 * 60 * 24);
  return days;
}

//获得本季度的开始月份     
function getQuarterStartMonth() {
  var quarterStartMonth = 0;
  if (nowMonth < 3) {
    quarterStartMonth = 0;
  }
  if (2 < nowMonth && nowMonth < 6) {
    quarterStartMonth = 3;
  }
  if (5 < nowMonth && nowMonth < 9) {
    quarterStartMonth = 6;
  }
  if (nowMonth > 8) {
    quarterStartMonth = 9;
  }
  return quarterStartMonth;
}

//获得本周的开始日期     
function getWeekStartDate() {
  var weekStartDate = new Date(nowYear, nowMonth, nowDay - nowDayOfWeek);
  return formatDate(weekStartDate);
}

//获得本周的结束日期     
function getWeekEndDate() {
  var weekEndDate = new Date(nowYear, nowMonth, nowDay + (6 - nowDayOfWeek));
  return formatDate(weekEndDate);
}

//获得本月的开始日期     
function getMonthStartDate() {
  var monthStartDate = new Date(nowYear, nowMonth, 1);
  return formatDate(monthStartDate);
}

//获得本月的结束日期     
function getMonthEndDate() {
  var monthEndDate = new Date(nowYear, nowMonth, getMonthDays(nowMonth));
  if (monthEndDate > now && nowMonth == monthEndDate.getMonth())
    monthEndDate = now;
  return formatDate(monthEndDate);
}

//获得本季度的开始日期     
function getQuarterStartDate() {
  var quarterStartDate = new Date(nowYear, getQuarterStartMonth(), 1);
  return formatDate(quarterStartDate);
}

//获的本季度的结束日期     
function getQuarterEndDate() {
  var quarterEndMonth = getQuarterStartMonth() + 2;
  var quarterStartDate = new Date(nowYear, quarterEndMonth, getMonthDays(quarterEndMonth));
  return formatDate(quarterStartDate);
}
//获得本年度的开始日期    
function getYearStartDate() {
  var yearStartDate = new Date(nowYear, 0, 1);
  return formatDate(yearStartDate);
}

export function getRangeTime(val = 'month') {
  if (val === 'year') {
    return [getYearStartDate(), getNowFormatDate()]
  } else if (val === 'quarter') {
    return [getQuarterStartDate(), getQuarterEndDate()]
  } else if (val === 'month') {
    return [getMonthStartDate(), getMonthEndDate()]
  } else if (val === 'week') {
    return [getWeekStartDate(), getWeekEndDate()]
  } else {
    return [getWeekStartDate(), getWeekEndDate()]
  }
}