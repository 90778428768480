<template>
  <div class="static-dialog load-dialog">
    <el-form :model="forms" :rules="rules" ref="forms" :label-width="labelWidth">
      <el-row :gutter="10">
        <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
          <el-form-item label="款式大类" prop="category">
            <el-select v-model="forms.category" placeholder="请选择" disabled>
              <el-option v-for="item in dict.categorys" :key="item.id" :label="item.name" :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>

        <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
          <el-form-item label="对应材质" prop="metalCode">
            <el-select v-model="forms.metalCode" placeholder="请选择" :disabled="forms.id">
              <el-option v-for="item in dict.metals" :key="item.id" :label="item.value" :value="item.name">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>

        <el-col :xs="24" :sm="16" :md="16" :lg="16" :xl="16">
          <el-form-item label="款式名称" prop="detailName" style="width: 95%">
            <el-input v-model="forms.detailName" autocomplete="off">
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="6" :md="6" :lg="6" :xl="6">
          <el-form-item label="" label-width="0" prop="detailCode">
            <el-input v-model="forms.detailCode" autocomplete="off" placeholder="SKU编号" disabled>
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="10" v-if="forms.detailCode">
        <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
          <el-form-item label="自定义款号" prop="serialCode">
            <el-input v-model="forms.serialCode" autocomplete="off" placeholder="自定义款号">
            </el-input>
          </el-form-item>
        </el-col>

        <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
          <el-form-item label="规格" prop="detailUnit" style="max-width: 85%">
            <el-input v-model="forms.detailUnit" autocomplete="off">
            </el-input>
          </el-form-item>
        </el-col>

        <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
          <el-form-item label="总质量" prop="totalWeight">
            <el-input v-model="forms.totalWeight" autocomplete="off">
            </el-input>
          </el-form-item>
        </el-col>

        <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
          <el-form-item label="状态" prop="status">
            <el-select v-model="forms.status" placeholder="请选择">
              <el-option label="正常" value="enabled"> </el-option>
              <el-option label="禁用" value="disabled"> </el-option>
            </el-select>
          </el-form-item>
        </el-col>

        <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
          <el-form-item label="所属品牌" prop="brandName" style="max-width: 85%">
            <el-select v-model="forms.brandName" placeholder="请选择">
              <el-option v-for="item in dict.brands" :key="item.id" :label="item.name" :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
          <el-form-item label="品牌款号" prop="brandModelCode" style="max-width: 85%">
            <el-input v-model="forms.brandModelCode" autocomplete="off"></el-input>
          </el-form-item>
        </el-col>

        <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
          <el-form-item label="款式工厂" prop="factoryName" style="max-width: 85%">
            <el-select v-model="forms.factoryName" placeholder="请选择">
              <el-option v-for="item in dict.factorys" :key="item.id" :label="item.name" :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
          <el-form-item label="工厂款号" prop="factoryModelCode" style="max-width: 85%">
            <el-input v-model="forms.factoryModelCode" autocomplete="off"></el-input>
          </el-form-item>
        </el-col>

        <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
          <el-form-item label="采购参考价" prop="purchasePrice" style="max-width: 70%">
            <el-input type="number" v-model="forms.purchasePrice" autocomplete="off"></el-input>
          </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
          <el-form-item label="售卖价" prop="sellPrice" style="max-width: 70%">
            <el-input type="number" v-model="forms.sellPrice" autocomplete="off"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>
<script>
import { fetchSkuByDetailCode, } from "@/api/product";
export default {
  name: "SkuDetailDialog",
  props: {
    detailCode: {
      type: String,
      default: "",
    },
  },
  data: function () {
    return {
      dict: {
        categorys: [],
        brands: [],
        factorys: [],
        metal: [],
      },
      forms: {},
      labelWidth: "110px",
    }
  },
  methods: {
  },
  mounted() {
    this.$kaung.dictionary("stock", "category").then((res) => { this.dict.categorys = res; });
    this.$kaung.dictionary("stock", "brand").then((res) => { this.dict.brands = res; });
    this.$kaung.dictionary("stock", "factory").then((res) => { this.dict.factorys = res; });
    this.$kaung.dictionary("stock", "metal").then((res) => { this.dict.metals = res; });

    var that = this;
    fetchSkuByDetailCode(this.detailCode).then((res) => {
      that.forms = res.data;
    });
  },
};
</script>